"use client";
import { useIsHovering } from "@/hooks/general/useIsHovering";
import { AnimatePresence, motion } from "framer-motion";
import { LinkInternal } from "../../components/link";
import { Arrow } from "../../components/icons/arrow";
import { ReactNode } from "react";
import { cn } from "@/lib/utils";
import { IconComponent } from "../../types";
import { Button } from "../../components/button";

export type CornerToStayBlue = "tl" | "tr" | "bl" | "br";
export type ProductCardType = "swap" | "pool" | "stake" | "bridge";

const title: Record<ProductCardType, string> = {
	swap: "swap",
	pool: "pool",
	stake: "stake",
	bridge: "bridge",
};

const description: Record<ProductCardType, string> = {
	swap: "Trade crypto instantly on ApeChain",
	pool: "provide liquidity to earn trading fees",
	stake: "Earn rewards while contributing to the ecosystem",
	bridge: "Seamless integration with ApeChain for asset transfer",
};

const CTATagline: Record<ProductCardType, string> = {
	swap: "Trade Now",
	pool: "Provide Liquidity",
	stake: "Stake Now",
	bridge: "Bridge Now",
};

const Href: Record<ProductCardType, string> = {
	swap: "/swap",
	pool: "/pools",
	stake: "/stake",
	bridge: "/bridge",
};

const isComingSoon: Record<ProductCardType, boolean> = {
	swap: false,
	pool: false,
	stake: true,
	bridge: true,
};

const cornerShouldStayBlue: Record<ProductCardType, CornerToStayBlue> = {
	swap: "tl",
	pool: "tr",
	stake: "br",
	bridge: "bl",
};

const _SwapIcon: IconComponent = (props) => (
	<svg {...props} viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21.6666 19.3333V22H5.66665V24.6666H2.99998L2.99998 22H0.333313L0.333313 19.3333H2.99998V16.6666H5.66665V19.3333H21.6666ZM5.66665 16.6666H8.33331V14H5.66665V16.6666ZM5.66665 24.6666H8.33331V27.3333H5.66665V24.6666ZM0.333313 5.99998L0.333313 8.66665H16.3333V11.3333L13.6666 11.3333L13.6666 14L16.3333 14V11.3333H19V8.66665H21.6666V5.99998H19V3.33331H16.3333V0.666649H13.6666V3.33331H16.3333V5.99998H0.333313Z"
			fill="#FAFAFA"
		/>
	</svg>
);

const _PoolIcon: IconComponent = (props) => (
	<svg {...props} viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.83334 15H8.91668V17.0834H6.83334V15Z" fill="#FAFAFA" />
		<path d="M13.0833 12.9167H11V15H13.0833V12.9167Z" fill="#FAFAFA" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.25 0.416687H4.75001V2.50002H6.83334V10.8334H4.75001V12.9167H2.66668V15H0.583344V21.25H2.66668V23.3334H4.75001V25.4167H6.83334V27.5H15.1667V25.4167H17.25V23.3334H19.3333V21.25H21.4167V15H19.3333V12.9167H17.25V10.8334H15.1667V2.50002H17.25V0.416687ZM15.1667 10.8334V12.9167H17.25V15H19.3333V21.25H17.25V19.1667H4.75001V21.25H2.66668V15H4.75001V12.9167H6.83334V10.8334H8.91668V2.50002H13.0833V10.8334H15.1667ZM6.83334 23.3334V25.4167H15.1667V23.3334H17.25V21.25H15.1667V23.3334H6.83334ZM6.83334 23.3334H4.75001V21.25H6.83334V23.3334Z"
			fill="#FAFAFA"
		/>
	</svg>
);

const _StakeIcon: IconComponent = (props) => (
	<svg {...props} viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.66667 24.1666V26H11.5V24.1666H15.1667V22.3333H11.5V20.5H15.1667V18.6666H17V15H15.1667V13.1666H9.66667V18.6666H7.83333V20.5H9.66667V22.3333H7.83333V24.1666H9.66667ZM15.1667 15H11.5V18.6666H15.1667V15Z"
			fill="#FAFAFA"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6 0.333313V2.16665H4.16667V5.83331H6V7.66665H7.83333V9.49998H6V11.3333H4.16667V13.1666H2.33333V15H0.5V24.1666H2.33333V26H4.16667V27.8333H6V29.6666H18.8333V27.8333H20.6667V26H22.5V24.1666H24.3333V15H22.5V13.1666H20.6667V11.3333H18.8333V9.49998H17V7.66665H18.8333V5.83331H20.6667V2.16665H18.8333V0.333313H6ZM18.8333 2.16665V5.83331H17V7.66665H7.83333V5.83331H6V2.16665H9.66667V3.99998H11.5V2.16665H13.3333V3.99998H15.1667V2.16665H18.8333ZM17 9.49998V11.3333H18.8333V13.1666H20.6667V15H22.5V24.1666H20.6667V26H18.8333V27.8333H6V26H4.16667V24.1666H2.33333V15H4.16667V13.1666H6V11.3333H7.83333V9.49998H17Z"
			fill="#FAFAFA"
		/>
	</svg>
);

const _BridgeIcon: IconComponent = (props) => (
	<svg {...props} viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 13.6667V17H1.66667V18.6667H6.66667V20.3334H5V22H3.33333V23.6667H5V22H6.66667V20.3334H8.33333V18.6667H13.3333V20.3334H15V22H16.6667V23.6667H18.3333V22H16.6667V20.3334H15V18.6667H21.6667V20.3334H23.3333V22H25V23.6667H26.6667V22H25V20.3334H23.3333V18.6667H28.3333V17H30V13.6667H28.3333V12H26.6667V8.66671H25V5.33337H23.3333V3.66671H21.6667V2.00004H18.3333V0.333374H11.6667V2.00004H8.33333V3.66671H6.66667V5.33337H5V8.66671H3.33333V12H1.66667V13.6667H0ZM5 8.66671H6.66667V5.33337H8.33333V3.66671H11.6667V2.00004H18.3333V3.66671H21.6667V5.33337H23.3333V8.66671H25V12H5V8.66671ZM1.66667 13.6667V17H28.3333V13.6667H1.66667Z"
			fill="#FAFAFA"
		/>
	</svg>
);

const ProductIcon: Record<ProductCardType, ReactNode> = {
	swap: <_SwapIcon className="w-3 sm:w-4 md:w-6" />,
	pool: <_PoolIcon className="w-3 sm:w-4 md:w-6" />,
	stake: <_StakeIcon className="w-3 sm:w-4 md:w-6" />,
	bridge: <_BridgeIcon className="w-3 sm:w-4 md:w-6" />,
};

export const ProductCard = ({ type }: { type: ProductCardType }) => {
	const { ref, isHovering } = useIsHovering();

	return (
		<div className="bg-[#1D2735] p-[6px] product-card-clip" ref={ref}>
			<LinkInternal
				href={isComingSoon[type] ? "" : Href[type]}
				className={cn(
					"bg-[#0D131C] p-4 sm:p-5 xl:p-8 min-h-[212px] md:min-h-[318px] product-card-inner-clip w-full flex flex-col gap-4 justify-between",
					isComingSoon[type] && "pointer-events-none"
				)}>
				<div className="flex justify-between items-center gap-4">
					<HoverIconItem
						cornerToStayBlue={cornerShouldStayBlue[type]}
						icon={ProductIcon[type]}
						isHovering={isHovering}
					/>
					{isComingSoon[type] ? (
						<Button variant={"badge"} size={"xxs"}>
							COMING SOON
						</Button>
					) : null}
				</div>
				<div className="uppercase">
					<h4 className="text-[#ECEDEF] text-[24px] font-bold md:text-[32px] leading-6 md:leading-10">
						{title[type]}
					</h4>
					<p className="text-[#BABFC6] text-[12px] md:text-base md:leading-5">{description[type]}</p>
					<AnimatePresence>
						{isHovering && (
							<motion.div
								key={`product-card-${type}`}
								initial={{ opacity: 0, height: 0 }}
								animate={{ opacity: 1, height: "auto" }}
								exit={{ opacity: 0, height: 0 }}
								transition={{ duration: 0.2 }}>
								<div className="text-accent flex font-[700]">
									{isComingSoon[type] ? (
										"coming soon"
									) : (
										<>
											{CTATagline[type]}
											<Arrow direction="right" width={20} height={20} />
										</>
									)}
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</LinkInternal>
		</div>
	);
};

const HoverIconItem = ({
	icon,
	isHovering,
	cornerToStayBlue,
}: {
	icon: ReactNode;
	isHovering: boolean;
	cornerToStayBlue: CornerToStayBlue;
}) => {
	return (
		<div className="relative flex items-center justify-center min-w-[44px] min-h-[44px]  sm:min-w-[52px] md:min-w-[68px] sm:min-h-[52px] md:min-h-[68px]">
			<IconEdge
				fill={cornerToStayBlue === "tl" ? "#1682FE" : isHovering ? "#FAFAFA" : "#1D2735"}
				className="absolute top-0 left-0 transform"
			/>

			<IconEdge
				fill={cornerToStayBlue === "tr" ? "#1682FE" : isHovering ? "#FAFAFA" : "#1D2735"}
				className="absolute top-0 right-0 rotate-90 transform"
			/>
			{icon}

			<IconEdge
				fill={cornerToStayBlue === "bl" ? "#1682FE" : isHovering ? "#FAFAFA" : "#1D2735"}
				className="absolute bottom-0 left-0 rotate-90 transform"
			/>

			<IconEdge
				fill={cornerToStayBlue === "br" ? "#1682FE" : isHovering ? "#FAFAFA" : "#1D2735"}
				className="absolute bottom-0 right-0 transform"
			/>
		</div>
	);
};

const IconEdge = ({ fill, className }: { fill: string; className: string }) => (
	<svg
		width="15"
		height="16"
		viewBox="0 0 15 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={cn(className)}>
		<rect x="10.0776" y="0.174988" width="4.69256" height="5.175" fill={fill} />
		<rect x="5.38513" y="5.34998" width="4.69256" height="5.175" fill={fill} />
		<rect x="0.692566" y="10.525" width="4.69256" height="5.175" fill={fill} />
	</svg>
);
