import { isMobile } from "@/utils/mobile-helpers";
import { useState, useEffect } from "react";

export default function useIsMobile() {
	const [mobile, setMobile] = useState(isMobile());

	useEffect(() => {
		const handleResize = () => {
			setMobile(isMobile());
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return mobile;
}
