"use client";
import {
	Dialog,
	DialogBody,
	DialogClose,
	DialogContent,
	DialogTitle,
	DialogTrigger,
} from "./components/dialog";
import { Button } from "./components/button";
import { LinkExternal } from "./components/link";
import { IconComponent } from "./types";
import { Arrow } from "./components/icons/arrow";

export const RestrictedAccess = () => {
	return (
		<Dialog>
			<DialogTrigger asChild className="w-full max-w-[140px] md:max-w-[150px]">
				<Button
					iconPosition="end"
					icon={() => <Arrow direction="right" className="w-[20px] mb-1" />}
					className="w-full max-w-[140px] md:max-w-[150px] uppercase">
					Launch App
				</Button>
			</DialogTrigger>
			<DialogContent variant={"desktop"} aria-describedby={undefined} className="swap-clip px-3 pb-3 pt-7">
				<DialogTitle className="px-4">Restricted Access</DialogTitle>
				<DialogBody className="output-clip flex flex-col px-10 pb-6 pt-10 gap-4">
					<_InfoIcon width={40} height={40} className="text-[#61696B]" />
					<p className="mt-4">
						This link is not available to persons who are residents of, are located or incorporated in, or
						have a registered agent in the U.S. or a restricted jurisdiction. More details can be found in our{" "}
						<LinkExternal href="/terms-of-use" className="text-accent">
							Terms of Use
						</LinkExternal>
					</p>
					<DialogClose asChild>
						<Button className="uppercase w-full">Go Back</Button>
					</DialogClose>
				</DialogBody>
			</DialogContent>
		</Dialog>
	);
};

const _InfoIcon: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20.3327 15.3332H16.666V29.9998H20.3327V15.3332Z" fill="currentColor" fillOpacity="0.8" />
			<path d="M20.3327 7.99984H16.666V11.6665H20.3327V7.99984Z" fill="currentColor" fillOpacity="0.8" />
			<path
				d="M34.9993 6.1665V4.33317H33.166V2.49984H31.3327V0.666504H5.66602V2.49984H3.83268V4.33317H1.99935V6.1665H0.166016V31.8332H1.99935V33.6665H3.83268V35.4998H5.66602V37.3332H31.3327V35.4998H33.166V33.6665H34.9993V31.8332H36.8327V6.1665H34.9993ZM33.166 28.1665H31.3327V29.9998H29.4993V31.8332H27.666V33.6665H9.33268V31.8332H7.49935V29.9998H5.66602V28.1665H3.83268V9.83317H5.66602V7.99984H7.49935V6.1665H9.33268V4.33317H27.666V6.1665H29.4993V7.99984H31.3327V9.83317H33.166V28.1665Z"
				fillOpacity="0.8"
				fill="currentColor"
			/>
		</svg>
	);
};
