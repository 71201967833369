import { useEffect, useRef, useState } from "react";
import useIsMobile from "./useIsMobile";

export const useIsHovering = () => {
	const [isHovering, setIsHovering] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);
	const isMobile = useIsMobile();

	const handleMouseMove = (event: MouseEvent) => {
		if (isMobile) {
			return;
		}

		const rect = ref.current?.getBoundingClientRect();
		if (!rect) {
			setIsHovering(false);
			return;
		}

		const isInside =
			event.clientX >= rect.left &&
			event.clientX <= rect.right &&
			event.clientY >= rect.top &&
			event.clientY <= rect.bottom;
		setIsHovering(isInside);
	};

	const handleTap = (event: TouchEvent) => {
		if (!isMobile) {
			return;
		}

		const rect = ref.current?.getBoundingClientRect();
		if (!rect) {
			setIsHovering(false);
			return;
		}

		if (event.touches.length > 0) {
			const touch = event.touches[0];
			if (!touch) {
				setIsHovering(false);
				return;
			}
			const isInside =
				touch.clientX >= rect.left &&
				touch.clientX <= rect.right &&
				touch.clientY >= rect.top &&
				touch.clientY <= rect.bottom;
			setIsHovering(isInside);
		} else {
			setIsHovering(false);
		}
	};

	useEffect(() => {
		if (isMobile) {
			document.addEventListener("touchstart", handleTap);
		} else {
			document.addEventListener("mousemove", handleMouseMove);
		}

		return () => {
			if (isMobile) {
				document.removeEventListener("touchstart", handleTap);
			} else {
				document.removeEventListener("mousemove", handleMouseMove);
			}
		};
	}, [isMobile]);

	return { ref, isHovering, isMobile };
};
