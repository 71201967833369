import { Button } from "../components/button";
import { Arrow } from "../components/icons/arrow";
import { LinkInternal } from "../components/link";
import { RestrictedAccess } from "../restricted-access";

export const LaunchApp = ({ isInRestrictedArea }: { isInRestrictedArea: boolean }) => {
	return (
		<>
			{isInRestrictedArea ? (
				<RestrictedAccess />
			) : (
				<LinkInternal className="w-full max-w-[140px] md:max-w-[150px]" href="/swap">
					<Button
						iconPosition="end"
						icon={() => <Arrow direction="right" className="w-[20px] mb-1" />}
						className="w-full max-w-[140px] md:max-w-[150px] uppercase">
						Launch App
					</Button>
				</LinkInternal>
			)}
		</>
	);
};
