import { cn } from "@/lib/utils";
import { FC, SVGProps } from "react";

interface ArrowProps extends SVGProps<SVGSVGElement> {
	direction?: "up" | "down" | "left" | "right";
}

export const Arrow: FC<ArrowProps> = ({ direction = "left", ...props }) => {
	return (
		<svg
			{...props}
			className={cn(
				props.className,
				direction === "up" && "transform rotate-90",
				direction === "down" && "transform rotate-[270deg]",
				direction === "right" && "transform rotate-180"
			)}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.6673 9.16602V10.8327H6.66732V12.4993H5.00065V10.8327H3.33398V9.16602H5.00065V7.49935H6.66732V9.16602H16.6673ZM8.33398 5.83268H6.66732V7.49935H8.33398V5.83268ZM8.33398 5.83268H10.0007V4.16602H8.33398V5.83268ZM8.33398 14.166H6.66732V12.4993H8.33398V14.166ZM8.33398 14.166H10.0007V15.8327H8.33398V14.166Z"
				fill="currentColor"
			/>
		</svg>
	);
};
