"use client";
import { cn } from "@/lib/utils";
import { Button } from "@/ui/components/button";
import { useState } from "react";
import { RewardMedia } from "./reward-media";
import { ExploreTabs } from "./explore-tabs";

export type ExploreRewardType = "bonobo" | "chimpanzee" | "gorilla";
export const ExploreRewardsSection = () => {
	const [selectedType, setSelectedType] = useState<ExploreRewardType>("bonobo");

	const handleSelect = (type: ExploreRewardType) => {
		setSelectedType(type);
	};

	return (
		<section className={cn("bg-[#151D28] flex flex-col items-center justify-center min-w-full")}>
			<div className=" pt-14 md:pt-28 pb-4 mx-auto max-w-[738px] w-full">
				<div className="text-center uppercase px-6 md:px-14">
					<h2 className="text-[#ECEDEF] font-bold text-[24px] md:text-[40px]">Explore Saru rewards</h2>
					<p className="text-[#BABFC6] text-base">Complete missions to EARN XPS and unlock future rewards.</p>
				</div>
				<ExploreTabs selectedType={selectedType} handleSelect={handleSelect} />
			</div>

			<div className="md:min-h-[720px] min-h-[550px] w-full relative">
				<RewardMedia type={selectedType} />
				<GradientOverlay />
				<div className="z-[2] absolute bottom-10 left-1/2 -translate-x-1/2">
					<Button className="uppercase">Coming Soon</Button>
				</div>
			</div>
		</section>
	);
};

const GradientOverlay = () => {
	return (
		<>
			<div
				style={{
					background:
						"linear-gradient(180deg, #151D28 0%, rgba(21, 29, 40, 0.5) 13.27%, rgba(21, 29, 40, 0.5) 68%, rgba(21, 29, 40, 0.9) 88.48%)",
				}}
				className="absolute inset-0 z-[1] w-full h-full hidden md:block"
			/>
			<div
				style={{
					background:
						"linear-gradient(180deg, #151D28 0%, rgba(21, 29, 40, 0) 16.96%, rgba(21, 29, 40, 0) 62.74%, rgba(21, 29, 40, 0.88) 75.25%)",
				}}
				className="absolute inset-0 z-[1] w-full h-full block md:hidden"
			/>
		</>
	);
};
