"use client";
import Image from "next/image";
export const SaruGif = () => {
	return (
		<Image
			src="/images/saru/saru.gif"
			loading="eager"
			width={180}
			height={180}
			alt="Saru Icon"
			priority
			quality={100}
		/>
	);
};
