import { Tabs, TabsList, TabsTrigger } from "@/ui/components/tabs";
import { ExploreRewardType } from "./explore-rewards-section";
import { ReactNode } from "react";
import { Bonobo } from "@/ui/components/icons/bonobo";
import { Chimpanzee } from "@/ui/components/icons/chimpanzee";
import { Gorilla } from "@/ui/components/icons/gorilla";

const TABS: { icon: ReactNode; label: string; value: ExploreRewardType }[] = [
	{
		icon: <Bonobo className="w-[35px] md:w-[45px]" />,
		label: "Bonobo",
		value: "bonobo",
	},
	{
		icon: <Chimpanzee className="w-[35px] md:w-[45px]" />,
		label: "Chimpanzee",
		value: "chimpanzee",
	},
	{
		icon: <Gorilla className="w-[35px] md:w-[45px]" />,
		label: "Gorilla",
		value: "gorilla",
	},
];

export const ExploreTabs = ({
	selectedType,
	handleSelect,
}: {
	selectedType: ExploreRewardType;
	handleSelect: (type: ExploreRewardType) => void;
}) => {
	return (
		<Tabs value={selectedType} className="w-full px-4 mt-4 md:mb-6 mb-4">
			<TabsList className="w-full border-b-transparent">
				{TABS.map((type) => (
					<TabsTrigger
						key={type.label}
						className="w-full data-[state=inactive]:border-[#82B6FF1A] border-b-2 data-[state=active]:border-[#82B6FF]"
						value={type.value}
						onClick={() => {
							handleSelect(type.value);
						}}>
						<div className="flex flex-col gap-1 items-center justify-center">
							{type.icon}
							<p className="uppercase text-base text-[20px] text-[#ECEDEF]">{type.label}</p>
						</div>
					</TabsTrigger>
				))}
			</TabsList>
		</Tabs>
	);
};
