"use client";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { SaruIcon } from "../components/icons/saru-icon";
import { LaunchApp } from "./launch-app";

export const Navbar = ({ isInRestrictedArea }: { isInRestrictedArea: boolean }) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			setIsVisible(window.scrollY > window.innerHeight);
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<AnimatePresence>
			{isVisible && (
				<motion.nav
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.25 }}
					className="fixed top-0 left-0 w-full bg-[#0D131C] shadow-md z-50">
					<div className="container mx-auto px-4 md:px-8 py-3 md:py-4 flex items-center justify-between">
						<div className="text-[#D1E5E5] flex items-center gap-2">
							<SaruIcon className="w-[38px] md:w-[42px]" />
							<span className="font-pixelify font-bold hidden md:block text-2xl mt-[5px]">SARU</span>
						</div>
						<div className="mr-2">
							<LaunchApp isInRestrictedArea={isInRestrictedArea} />
						</div>
					</div>
				</motion.nav>
			)}
		</AnimatePresence>
	);
};
