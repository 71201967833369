import Image from "next/image";
import { ExploreRewardType } from "./explore-rewards-section";
import { cn } from "@/lib/utils";

export const RewardMedia = ({ type }: { type: ExploreRewardType }) => {
	return (
		<>
			<video
				className={cn(
					"object-cover absolute inset-0 z-0 w-full h-full",
					type === "bonobo" ? "block" : "hidden"
				)}
				autoPlay={true}
				controls={false}
				loop={true}
				muted={true}
				playsInline={true}
				src="/videos/backgrounds/bonobo.webm"
			/>

			<Image
				src="/videos/backgrounds/chimpanzee.gif"
				className={cn(
					"object-cover absolute inset-0 z-0 w-full h-full",
					type === "chimpanzee" ? "block" : "hidden"
				)}
				alt="chimp"
				loading="eager"
				priority
				width={1160}
				height={720}
			/>

			<Image
				src="/videos/backgrounds/gorilla.gif"
				className={cn(
					"object-cover absolute inset-0 z-0 w-full h-full",
					type === "gorilla" ? "block" : "hidden"
				)}
				width={1160}
				height={720}
				priority
				alt="gorilla"
				loading="eager"
			/>
		</>
	);
};
